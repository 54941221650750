export default (api) => ({
  signIn: (data) => {
    return api.post(
      `/users/sign_in`,
      {
        user: {
          email: data.email,
          password: data.password
        }
      }
    )
  },
  signUp: (params) => {
    return api.post(
      `/users`,
      {
        user: params
      }
    )
  },
  forgotPassword: (params) => {
    return api.post(
      `/users/forgot_password`,
      params
    )
  },
  updatePassword: (params) => {
    return api.put(
      `/users/password`,
      params
    )
  },
})
<template>
  <div class="d-flex flex-column align-items-center">
    <img src="@/assets/images/logo-purple.png" alt="Carregando" width="135"/>
    <span class="fw-bold fc-label mt-3">Carregando...</span>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Loading',
  created() {
    if (!this.currentUser) {
      this.$router.push('/sign-in')
    }
  },
  computed: {
    ...mapState('auth', ['currentUser'])
  }
}
</script>
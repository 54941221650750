<template>
  <div style="position: absolute; top: 30px; right: 30px;">
    <button class="btn btn-link" @click="toggleMenu">
      <img src="@/assets/images/profile.png" alt="Perfil" />
    </button>
    <div v-if="showMenu" class="profile-drop-down">
      <button class="btn btn-link" @click="logout">Sair da conta</button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { onSignout } from '@/services/api'
export default {
  name: 'ProfileButton',
  data() {
    return {
      showMenu: false
    }
  },
  created() {
    if (!this.currentUser) {
      this.$router.push('/sign-in')
    }
  },
  computed: {
    ...mapState('auth', ['currentUser'])
  },
  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu
    },
    logout() {
      this.setCurrentUser({})
      onSignout()
      this.$router.push('/sign-in')
    },
    ...mapActions('auth', ['setCurrentUser']),
  }
}
</script>

<style scoped>
.profile-drop-down {
  position: absolute;
  top: 50px;
  right: 0;
  background-color: white;
  border: 1px solid #9E9B9B;
  border-radius: 10px;
  padding: 10px;
  width: 150px;
  text-align: center;
}

.profile-drop-down button {
  text-decoration: none;
  color: #9E9B9B;
}

</style>
<template>
  <div class="background-story-screen d-flex justify-content-center align-items-center" ref="content-account-show" @mousemove="onMouseMove" @mouseleave="onMouseLeave">
    <transition name="fade">
      <router-link to="/accounts" class="btn btn-link back-button" v-if="!loading && showButton" style="z-index: 9;" ref="back-button">
        <img src="@/assets/images/arrow-left-silver.png" alt="Voltar" />
      </router-link>
    </transition>
    <Loading v-if="loading" />
    <div class="background-story-screen d-flex justify-content-center align-items-center" v-if="!loading">
      <div :class="classContentStoryScreen"
            class="content-story-screen"
           ref="items-story-screen"
           v-for="n in quantity_show"
           :key="n">
        <img
          v-if="isImage(showing[n].index)"
          :src="medias[showing[n].index].media_url"
          alt="midia"
          class="img-fluid"
          :class="classImgFluid"
          :ref="`view-image-${n}`"
        />
        <video
          v-else-if="isVideo(showing[n].index)"
          :src="medias[showing[n].index].media_url"
          alt="midia"
          :controls="false"
          autoplay
          muted
          class="img-fluid"
          :class="classImgFluid"
          :ref="`view-video-${n}`"
          @ended="() => nextMedia(n)"
          @loadedmetadata="() => setNextUpdate(n)"
        />
        <span style="color: #FFF;" v-if="this.medias.length === 0">Carregando</span>
        <img src="@/assets/images/horizontal_with_border.svg"
             alt="MeuStory.TV"
             class="logo-bottom"
              v-if="!loading && n == quantity_show"
        />
      </div>


      <button class="btn-fullscreen" @click="toggleFullscreen" v-if="showButton" ref="toggle-full-screen">
        <img src="@/assets/images/fullscreen.png" alt="Tela cheia" />
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import api from '@/services/api'
import Loading from '@/components/Loading.vue'

const MEDIA_TYPES = {
  image: 'IMAGE',
  video: 'VIDEO'
}

const MIN_MEDIA_MORE_ONE = 6
const TIME_TO_LOAD = 2

export default {
  name: 'AccountsShowView',
  components: {
    Loading
  },
  data() {
    return {
      medias: [],
      currentMedia: 0,
      loading: true,
      searchingMedia: false,
      account: {
        view: 0
      },
      quantity_show: 1,
      showing: {},
      reels: [],
      showButton: false
    }
  },
  async created() {
    if (!this.currentUser) {
      this.$router.push('/sign-in')
    }

    await this.fetchAccount().then(response => {
      this.account = response.data

      if (this.account.mode === 'vertical') {
        // this.$refs['back-button'].classList.add('back-button-vertical')
        // this.$refs['content-account-show'].style.height = '100vh'
        // this.$refs['content-account-show'].style.width = '100vh'
        // this.$refs['content-account-show'].classList.add('rotate')
      }
    })

    this.fetchMedias().then(response => {
      if (response.data.length === 0) {
        this.$router.push('/accounts')
        return
      }

      this.medias = response.data

      this.mountShowing()

      for (let i = 0; i < this.quantity_show; i++) {
        const position = i + 1
        const media = this.medias[i]
        this.showing[position].updating = true

        setTimeout(() => {
          try {
            const duration = this.getDuration(media.media_type, position)
            this.showing[position].next_update = new Date(new Date().setSeconds(new Date().getSeconds() + TIME_TO_LOAD + ((duration + 1000) / 1000)))
            
            if (this.isImage(this.showing[position].index)) {
              setTimeout(() => {
                this.nextMedia(position)
              }, duration)
            }
            this.watchNextUpdate(position)
          } finally {
            if (this.isImage(this.showing[position].index)) {
              this.showing[position].updating = false
            }
          }
        }, 1000)
      }
    })
    .catch((error) => {
      console.error(error)
      this.$router.push('/accounts')
    })
    .finally(() => {
      this.loading = false
    })
  },
  mounted() {
    document.addEventListener('keydown', this.handleKeyPress)
  },
  computed: {
    mediaTypes() {
      return MEDIA_TYPES
    },
    classContentStoryScreen() {
      return this.account.mode === 'vertical' ? 'content-story-screen-vertical' : ''
    },
    classImgFluid() {
      return this.account.mode === 'vertical' ? 'img-fluid-vertical' : ''
    },
    ...mapState('auth', ['currentUser'])
  },
  methods: {
    handleKeyPress(event) {
      // Verifica se a tecla "seta para cima" foi pressionada
      if (event.key === "ArrowUp") {
        this.toggleFullscreen();
      }
    },
    fetchAccount() {
      return api.accounts.getById(this.$route.params.id)
    },
    async fetchMedias() {
      const data = []

      if (this.account.view !== 'reels') {
        const { data: stories } = await api.accounts.stories(this.$route.params.id)
        data.push(...stories)
      }

      if (this.reels.length === 0 && this.account.view !== 'stories') {
        const { data: reels } = await api.accounts.reels(this.$route.params.id)
        this.reels = reels
        data.push(...this.reels)
      }

      if (this.account.random) {
        for (let i = data.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [data[i], data[j]] = [data[j], data[i]];
        }
      }

      if (data.length > MIN_MEDIA_MORE_ONE) this.quantity_show = this.account.quantity_show

      return { data }
    },
    async nextMedia(position) {
      if (this.searchingMedia) {
        setTimeout(() => {
          this.nextMedia(position)
        }, 100)
        return
      }

      try {
        this.searchingMedia = true
        this.showing[position].updating = true
        // console.log(this.$refs[`view-${this.medias[this.showing[position].index].media_type.toLowerCase()}-${position}`])
        // this.$refs[`view-${this.medias[this.showing[position].index].media_type.toLowerCase()}-${position}`][0].classList.add('d-none')
        const lastShow = Object.values(this.showing).reduce((max, current) => 
          current.updated_at > max.updated_at ? current : max
        )
        const hasMoreMedias = lastShow.index < this.medias.length - 1
  
        if (hasMoreMedias) {
          this.showing[position].index = lastShow.index + 1
        } else {
          this.showing[position].index = 0
          this.fetchMedias().then(response => {
            const newMedias = response.data.filter(media => !this.medias.find(m => m.id === media.id))
            this.medias.push(...newMedias)
          })
        }

        this.showing[position].updated_at = new Date()
        if (this.isVideo(this.showing[position].index)) {
          setTimeout(() => {
            if (this.$refs[`view-video-${position}`] && this.$refs[`view-video-${position}`][0]) {
              if (this.$refs[`view-video-${position}`][0].paused) {
                this.$refs[`view-video-${position}`][0].load()
              }
            }
          }, 200)
        }

        setTimeout(() => {
          try {
            const index = this.showing[position].index
            const media = this.medias[index]
            const duration = this.getDuration(media.media_type, position)
  
            this.showing[position].next_update = new Date(new Date().setSeconds(new Date().getSeconds() + TIME_TO_LOAD + (duration / 1000)))
  
            if (this.isVideo(index)) return
  
            setTimeout(() => {
              this.nextMedia(position)
            }, duration)
          } finally {
            if (this.isImage(this.showing[position].index)) {
              this.showing[position].updating = false
            }
          }
        }, 1000)
      } finally {
        this.searchingMedia = false
      }
    },
    getDuration(media_type, position) {
      let video

      if (media_type === MEDIA_TYPES.video) {
        video = this.$refs[`view-video-${position}`]
        video = video ? video[0] : null
      }

      const videoDuration = video?.duration ? Math.round(video.duration) : null
      return ((videoDuration || this.account.duration) * 1000) - 1250
    },
    isVideo(index) {
      if (!this.medias[index]) return

      return this.medias[index].media_type === MEDIA_TYPES.video
    },
    isImage(index) {
      if (!this.medias[index]) return

      return this.medias[index].media_type === MEDIA_TYPES.image
    },
    setNextUpdate(position) {
      const index = this.showing[position].index
      const media = this.medias[index]
      const duration = this.getDuration(media.media_type, position)

      this.showing[position].next_update = new Date(new Date().setSeconds(new Date().getSeconds() + duration / 1000))
      this.showing[position].updating = false 
      // this.$refs[`view-${media.media_type.toLowerCase()}-${position}`][0].classList.remove('d-none')
    },
    watchNextUpdate(position) {
      setInterval(() => {
        if (this.showing[position].updating) return

        const now = new Date().setSeconds(new Date().getSeconds() - 1)
        const nextUpdate = this.showing[position].next_update

        if (nextUpdate < now) this.nextMedia(position)
      }, 500)
    },
    redirectToAccounts() {
      this.$router.push('/accounts')
    },
    onMouseMove() {
      this.showButton = true;
      this.resetButtonTimer();
    },
    onMouseLeave() {
      this.showButton = false;
    },
    resetButtonTimer() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.showButton = false;
      }, 3000);
    },
    toggleFullscreen() {
      if (!this.isFullscreen) {
        this.enterFullscreen();
      } else {
        this.exitFullscreen();
      }
      this.isFullscreen = !this.isFullscreen;
    },
    enterFullscreen() {
      let elem = document.documentElement; 
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      }
    },
    exitFullscreen() {
      try {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } catch (error) {
        console.error(error)
      }
    },
    mountShowing() {
      for (let i = 1; i <= this.quantity_show; i++) {
        this.showing[i] = {
          index: i - 1,
          updated_at: new Date().setSeconds(i*-2),
          next_update: new Date().setSeconds(i*-1),
          updating: false
        }
      }
    }
  },
  beforeUnmount() {
    clearTimeout(this.timer);
  }
}
</script>

<style scoped>
.back-button {
  position: absolute;
  top: 30px;
  left: 30px;
}

.back-button-vertical {
  right: 30px;
  rotate: 90deg;
}

.background-story-screen {
  background-color: #1A1A1A;
  width: 100%;
  height: 100%;
}

.content-story-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 33.33vw;
}

.content-story-screen-vertical {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vw;
  width: 100vh;
  rotate: 90deg;
}

.img-fluid {
  width: 33.33vw;
  height: 100%;
  object-fit: cover;
}

.img-fluid-vertical {
  width: 100vh !important;
  height: 100% !important;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.btn-fullscreen {
  position: absolute;
  bottom: 30px;
  right: 30px;
  z-index: 9;
  background-color: transparent;
  border: none;
}

.btn-fullscreen img {
  width: 50px;
}

.logo-bottom {
  position: absolute;
  bottom: 30px;
  width: 200px;
  right: 30px;
  /* opacity: .5; */
}

.left-60-percent {
  transform: translateX(-50%);
  left: 60%;
}
</style>

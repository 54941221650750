<template>
  <div class="background-primary align-content-center full-screen">
    <div class="full-screen d-flex flex-column align-items-center">
      <div class="d-flex flex-column align-items-center justify-content-center logo-bg">
      </div>
        <div class="logo mt-5 mb-5 d-flex justify-content-center content-width">
          <img src="@/assets/images/logo-horizontal-white.png" alt="MeuStory.TV" />
        </div>
        <div class="d-flex flex-column border-login full-screen content-width">
          <button type="button" v-if="!showFormLogin" class="primary-button mt-4" @click="showLogin">
            <span class="fw-bold fs-15">
              Acesse sua conta
            </span>
          </button>
          <div v-if="showFormLogin">
            <span class="title-auth">Login</span>
            <form @submit.prevent="submitForm" class="p-4 d-flex flex-column">
              <input placeholder="E-mail" type="email" v-model="v$.userEmail.$model" class="input-login" />
              <span v-if="v$.userEmail.$errors.length && submitted" class="color-danger fs-12 mt-3">{{ v$.userEmail.$errors[0]?.$message }}</span>
              <input placeholder="Senha" type="password" v-model="v$.password.$model" class="input-login mt-3" />
              <span v-if="v$.password.$errors.length && submitted" class="color-danger fs-12 mt-3">{{ v$.password.$errors[0]?.$message }}</span>
              <span v-if="error_message && submitted" class="color-danger fs-12 mt-3">{{ error_message }}</span>
              <router-link to="/forgot-password" class="mt-3 align-self-end link-forgot-password fw-bold">
                Esqueci minha senha
              </router-link>
              <button type="submit" class="primary-button mt-4">
                <span class="fw-bold fs-15">
                  Entrar
                </span>
              </button>
              <button type="button" class="primary-button-outlined mt-3" @click="$router.push('/sign-up')">
              <span class="fw-bold fs-15">
                Criar uma conta
              </span>
            </button>
            </form>
          </div>
          <button type="button" class="primary-button-outlined mt-3" @click="$router.push('/sign-up')" v-if="!showFormLogin">
            <span class="fw-bold fs-15">
              Criar uma conta
            </span>
          </button>
        </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { mapActions, mapState } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import { required, email, helpers } from '@vuelidate/validators'
import api from '@/services/api'

export default {
  name: 'SignInView',
  data() {
    return {
      submitted: false,
      error_message: '',
      showFormLogin: false
    }
  },
  created() {
    if (this.currentUser) {
      this.$router.push('/accounts')
    }
  },
  computed: {
    ...mapState('auth', ['currentUser'])
  },
  methods: {
    ...mapActions('auth', ['setCurrentUser']),
    async submitForm() {
      try {
        this.submitted = true
        const isFormCorrect = await this.v$.$validate()
        if (!isFormCorrect) return

        const params = {
          email: this.v$.userEmail.$model,
          password: this.v$.password.$model
        }

        const response = await api.auth.signIn(params)

        const { data } = response

        this.setCurrentUser(data)
        this.$router.push('/accounts')
      } catch (error) {
        console.error('Erro ao fazer login:', error)
        this.error_message = error.response.data.error[0]
      }
    },
    showLogin() {
      this.showFormLogin = true
    }
  },
  setup () {
    const userEmail = ref('')
    const password = ref('')

    const rules = {
      userEmail: {
        required: helpers.withMessage('Informe seu e-mail', required),
        email: helpers.withMessage('Informe um e-mail válido', email)
      },
      password: {
        required: helpers.withMessage('Informe sua senha', required)
      }
    }

    const v$ = useVuelidate(rules, {
      userEmail,
      password
    })

    return { v$ }
  }
}
</script>

<style scoped>
.logo {
  position: relative;
}

@media (min-width: 768px) {
  /* .logo-bg {
    position: absolute;
    background-image: url('@/assets/images/logo-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    width: 815px;
    margin-top: 90px;
  } */
}

</style>
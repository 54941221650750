<script>
import { mapState } from 'vuex'
export default {
  name: 'InitialView',
  created() {
    if (this.currentUser) {
      this.$router.push('/accounts')
    } else {
      this.$router.push('/sign-in')
    }
  },
  computed: {
    ...mapState('auth', ['currentUser'])
  }
}
</script>
<template>
  <div class="background-primary align-content-center full-screen">
    <div class="full-screen d-flex flex-column align-items-center">
      <div class="d-flex flex-row align-items-center content-width justify-content-center position-relative">
        <router-link to="/sign-in" class="back-link">
          <img src="@/assets/images/arrow-left.png" alt="Voltar" />
        </router-link>
      </div>
      <div class="margin-top-background-login border-login full-screen content-width">
        <span class="title-auth">Recuperar senha</span>
        <form @submit.prevent="submitForm" class="p-4 d-flex flex-column" v-if="showForm">
          <input placeholder="E-mail" type="email" v-model="v$.userEmail.$model" class="input-login" />
          <span v-if="v$.userEmail.$errors.length && submitted" class="color-danger fs-12 mt-3">{{ v$.userEmail.$errors[0]?.$message }}</span>
          <button type="submit" class="primary-button mt-4">
            <span class="fw-bold fs-15">
              Recuperar senha
            </span>
          </button>
        </form>
        <div v-else>
          <div class="d-flex flex-column align-items-center mt-3">
            <span class="fw-bold">
              Recuperação de senha
            </span>
            <span class="fw-bold">
              enviada para o seu e-mail.
            </span>
          </div>
          <div class="d-flex flex-column">
            <button type="button" class="primary-button mt-4" @click="redirectToLogin">
              <span class="fw-bold fs-15">
                Voltar para o login
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { mapActions, mapState } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import { required, email, helpers } from '@vuelidate/validators'
import api from '@/services/api'

export default {
  name: 'SignInView',
  data() {
    return {
      showForm: true,
      submitted: false
    }
  },
  created() {
    if (this.currentUser) {
      this.$router.push('/accounts')
    }
  },
  computed: {
    ...mapState('auth', ['currentUser'])
  },
  methods: {
    ...mapActions('auth', ['setCurrentUser']),
    async submitForm() {
      try {
        this.submitted = true
        const isFormCorrect = await this.v$.$validate()
        if (!isFormCorrect) return

        const params = {
          email: this.v$.userEmail.$model
        }

        const response = await api.auth.forgotPassword(params)

        if (response.status === 200) {
          this.showForm = false
        }
      } catch (error) {
        console.error('Erro ao enviar solicitação de recuperação de senha:', error)
      }
    },
    redirectToLogin() {
      this.$router.push('/sign-in')
    }
  },
  setup () {
    const userEmail = ref('')

    const rules = {
      userEmail: {
        required: helpers.withMessage('Informe seu e-mail', required),
        email: helpers.withMessage('Informe um e-mail válido', email)
      }
    }

    const v$ = useVuelidate(rules, {
      userEmail
    })

    return { v$ }
  }
}
</script>
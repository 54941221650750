<template>
  <div class="container">
    <h1>Termos de Uso</h1>
    <p>Bem-vindo ao nosso aplicativo que visualiza os stories da conta do Instagram do usuário. Ao usar nosso aplicativo, você concorda com os seguintes Termos de Uso. Por favor, leia atentamente.</p>

    <h2>Uso do Aplicativo</h2>
    <p>Você deve usar nosso aplicativo apenas para fins legais e de acordo com os Termos de Uso. Ao utilizar nosso aplicativo, você concorda em não:</p>
    <ul>
      <li>Usar o aplicativo de maneira que viole qualquer lei ou regulamento local, estadual, nacional ou internacional.</li>
      <li>Usar o aplicativo para transmitir, ou obter o envio de qualquer material publicitário ou promocional não solicitado ou não autorizado.</li>
      <li>Impersonar ou tentar se passar por qualquer outra pessoa ou entidade.</li>
    </ul>

    <h2>Conta e Segurança</h2>
    <p>Para usar nosso aplicativo, você pode ser solicitado a fornecer certas informações de registro, incluindo seu nome de usuário do Instagram e token de acesso. Você concorda em fornecer informações precisas e completas e em manter a confidencialidade das suas credenciais de login.</p>

    <h2>Privacidade</h2>
    <p>O uso do nosso aplicativo também está sujeito à nossa <a href="/privacy-policy">Política de Privacidade</a>. Por favor, revise nossa Política de Privacidade para entender nossas práticas.</p>

    <h2>Propriedade Intelectual</h2>
    <p>Todo o conteúdo do aplicativo, incluindo, mas não se limitando a, texto, gráficos, logotipos, ícones, imagens, clipes de áudio, downloads digitais e software, é propriedade nossa ou de nossos licenciadores e está protegido por leis de direitos autorais e outras leis de propriedade intelectual.</p>

    <h2>Isenção de Garantias</h2>
    <p>O aplicativo é fornecido "como está" e "conforme disponível". Não garantimos que o aplicativo será ininterrupto, livre de erros ou seguro. Não oferecemos nenhuma garantia, expressa ou implícita, sobre a operação do aplicativo ou as informações, conteúdo ou materiais nele incluídos.</p>

    <h2>Limitação de Responsabilidade</h2>
    <p>Em nenhuma circunstância seremos responsáveis por quaisquer danos diretos, indiretos, incidentais, especiais ou consequenciais resultantes do uso ou da incapacidade de usar o aplicativo, incluindo, mas não se limitando a, danos por perda de lucros, uso, dados ou outras perdas intangíveis.</p>

    <h2>Alterações aos Termos de Uso</h2>
    <p>Podemos revisar e atualizar estes Termos de Uso periodicamente a nosso critério. Todas as alterações entram em vigor imediatamente quando as publicamos, e se aplicam ao uso do aplicativo a partir de então. Seu uso continuado do aplicativo após a publicação dos Termos de Uso revisados significa que você aceita e concorda com as alterações.</p>

    <h2>Contato</h2>
    <p>Se você tiver alguma dúvida sobre estes Termos de Uso, entre em contato conosco através do e-mail: <a href="mailto:vinnialfonso@hotmail.com">vinnialfonso@hotmail.com</a>.</p>

    <h4>Atenciosamente,</h4>
    <p>Equipe MeuStory.TV</p>

    <br />
    <br />
    <br />
  </div>
</template>

<script>
export default {
  name: 'TermsOfServiceView'
}
</script>

<style scoped>
  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 100px;
  }
  h1, h2, h3 {
    color: #333;
  }
  p {
    margin-bottom: 20px;
  }
</style>

<template>
  <div class="container">
    <h1>Política de Privacidade</h1>
    <p>O aplicativo MeuStory.TV coleta informações básicas do usuário para encontrar a conta do instagram que deseja fazer a visualização.</p>

    <p>Esta Política de Privacidade explica como coletamos, usamos, protegemos e compartilhamos as informações pessoais dos usuários do nosso aplicativo, que consulta e exibe stories e reels do Instagram em uma TV com três vídeos rodando simultaneamente.</p>

    <h2>Informações que Coletamos</h2>
    <p>Ao utilizar nosso aplicativo, podemos coletar as seguintes informações:</p>
    <ul>
      <li><strong>Informações da Conta do Instagram:</strong> Para acessar e exibir stories e reels da sua conta do Instagram vinculada a uma página, coletamos seu nome de usuário e o token de acesso da conta do Instagram autorizada.</li>
      <li><strong>Informações de Mídia:</strong> Coletamos dados de mídia, como vídeos e imagens dos stories e reels de sua conta do Instagram para exibição em nosso aplicativo.</li>
      <li><strong>Informações de Uso:</strong> Coletamos informações sobre como você utiliza o aplicativo, incluindo o tempo de visualização e interações com os vídeos exibidos.</li>
    </ul>

    <h2>Uso das Informações</h2>
    <p>Utilizamos as informações coletadas para os seguintes fins:</p>
    <ul>
      <li><strong>Exibição de Mídia:</strong> Para exibir os stories e reels da sua conta do Instagram em uma tela com até três vídeos rodando simultaneamente.</li>
      <li><strong>Melhoria do Serviço:</strong> Para entender melhor como os usuários interagem com nosso aplicativo e aprimorar a experiência oferecida.</li>
      <li><strong>Comunicação:</strong> Para enviar atualizações, notificações ou informações relacionadas ao uso do aplicativo, conforme necessário.</li>
    </ul>

    <h2>Compartilhamento de Informações</h2>
    <p>Não compartilhamos suas informações pessoais com terceiros, exceto nas seguintes circunstâncias:</p>
    <ul>
      <li>Quando exigido por lei ou para cumprir uma obrigação legal.</li>
      <li>Para proteger nossos direitos, privacidade, segurança ou propriedade.</li>
      <li>Com provedores de serviços terceirizados que nos auxiliam na operação e suporte do aplicativo, desde que eles concordem em manter a confidencialidade de suas informações.</li>
    </ul>

    <h2>Segurança das Informações</h2>
    <p>Implementamos medidas de segurança adequadas para proteger suas informações pessoais contra acesso, alteração, divulgação ou destruição não autorizados. No entanto, nenhuma transmissão de dados pela Internet ou sistema de armazenamento é completamente seguro, e não podemos garantir a segurança absoluta das informações.</p>

    <h2>Seus Direitos</h2>
    <p>Você tem o direito de acessar, corrigir, atualizar ou excluir suas informações pessoais armazenadas por nós. Para exercer esses direitos, entre em contato conosco através das informações fornecidas abaixo.</p>

    <h2>Alterações a Esta Política de Privacidade</h2>
    <p>Podemos atualizar esta Política de Privacidade periodicamente. Notificaremos você sobre quaisquer alterações publicando a nova Política de Privacidade em nosso aplicativo. Recomendamos que você revise esta política regularmente para estar ciente de quaisquer atualizações.</p>

    <h2>Contato</h2>
    <p>Se você tiver dúvidas ou preocupações sobre esta Política de Privacidade, entre em contato conosco através do e-mail: <a href="mailto:contato@meustory.tv">contato@meustory.tv</a>.</p>

    <p>Atenciosamente,</p>
    <p>Equipe MeuStory.TV</p>

    <br />
    <br />
    <br />
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicyView'
}
</script>

<style scoped>
  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 100px;
  }
  h1, h2, h3 {
    color: #333;
  }
  p {
    margin-bottom: 20px;
  }
</style>

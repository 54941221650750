<template>
  <router-view />
</template>

<script>
import { useHead } from '@vueuse/head'
export default {
  name: 'App',
  setup () {
    useHead({
      meta: [
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1, maximum-scale=1'
        }
      ]
    })
  }
}
</script>

<style>
.rotate {
  transform: rotate(90deg);
  transform-origin: center;
  text-align: center;
  /* padding: 40px; */
}

.background-primary {
  background-color: #902584 !important;
}

body {
  width: 100vw;
  height: 100vh;
  font-family: 'Montserrat', sans-serif;
}

#app {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.color-danger {
  color: #C00101;
}

.back-link {
  position: absolute;
  top: 40px;
  left: 40px;
  z-index: 1;
  text-decoration: none;
  color: white;
}

.w-500 {
  width: 500px;
  max-width: 80%;
}

.full-screen {
  height: 100%;
  width: 100%;
}

.pv-17 {
  padding-top: 68px;
  padding-bottom: 68px;
}

.ph-13 {
  padding-top: 52px;
  padding-bottom: 52px;
}

a, a:hover, a:active, a:visited {
  color: #5A5858;
}

.color-primary {
  color: #701E66;
}

.button {
  width: 290px;
  height: 58px;
  border-radius: 30px;
  border: none;
}

.button-primary {
  background-color: #701E66;
  color: white;
}

.button-primary-outline {
  background-color: white;
  color: #701E66;
  border: 1px solid #701E66;
}

.margin-top-background-login {
  margin-top: 137px;
}

.fs-8 {
  font-size: 8px;
}

.fs-12 {
  font-size: 12px;
}

.fs-15 {
  font-size: 15px;
}

.fs-20 {
  font-size: 20px;
}

.fs-30 {
  font-size: 30px;
}

.fc-label {
  color: #5A5858;
}

.circle-green {
  border: 3px solid #00FF38;
}

/* Auth CSS */
.content-width {
  width: 390px;
}

@media (max-width: 767px) {
  .content-width {
    width: 370px;
  }
}

.border-login {
  padding: 28px;
  border-top-left-radius: 69px;
  border-top-right-radius: 69px;
  background-color: white;
}

.primary-button {
  background-color: #701E66;
  color: white;
  border: none;
  border-radius: 30px;
  padding: 10px;
  height: 58px;
  cursor: pointer;
  font-weight: bold;
}

.primary-button-outlined {
  background-color: white;
  color: #701E66;
  border: 1px solid #701E66;
  border-radius: 30px;
  padding: 10px;
  height: 58px;
  cursor: pointer;
  font-weight: bold;
}

.input-login {
  height: 58px;
  background-color: #ECECEC;
  border: none;
  border-radius: 30px;
  padding: 10px 10px 10px 20px;
}

.title-auth {
  font-size: 30px;
  font-weight: bold;
  padding-left: 47px;
}

.link-forgot-password {
  color: #5A5858;
  text-decoration: none;
  font-size: 12px;
}

/* End Auth CSS */

@media screen and (max-width: 768px) {
  .fs-15 {
    font-size: 13px;
  }
  
  .fs-30 {
    font-size: 20px;
  }

  .button {
    width: 240px;
  }
}
</style>
